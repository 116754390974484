@font-face {
	font-family: 'Poppins-Regular.ttf';
	src: url(../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
	font-family: 'Poppins-Bold.ttf';
	src: url(../../assets/fonts/Poppins-Bold.ttf);
}

#login {
	background-image: url(../../assets/images/bg.png);
	height: 100vh;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	font-size: 18px;
	color: var(--bg-color-inverted);
	padding: 0 0 60px 0;

	& .header {
		background-color: #0033a0;
		padding: 10px 20px;
		margin-bottom: 10%;

		& img {
			width: 10%;
		}
	}

	& h1 {
		font-family: 'Poppins-Bold.ttf';
		margin-bottom: 10px;
	}

	& p {
		margin-top: 10px;
		font-family: 'Poppins-Regular.ttf';

		& .azul {
			color: #0033a0;
			font-weight: bold;
			font-family: 'Poppins-Bold.ttf';
		}
	}

	& .contenedor {
		background-color: rgba($color: #fff, $alpha: 0.5);
		padding: 40px;
		border-radius: 20px;

		.MuiInputBase-input {
			font-family: 'Poppins-Regular.ttf';
			background-color: #fff;
			color: #707070 !important;
			font-size: 18px;
		}

		& .MuiInput-underline {
			&::after {
				border-bottom: 2px solid #707070 !important;
			}

			&::before {
				border-bottom: 2px solid #707070 !important;
			}
		}

		& .MuiFormLabel-root {
			&.Mui-focused {
				font-family: 'Poppins-Regular.ttf';
				color: #707070 !important;
			}
		}

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(0px, -18px) scale(1) !important;
			font-family: 'Poppins-Regular.ttf';
			color: #707070 !important;
		}

		& .MuiFormLabel-root {
			color: #707070 !important;
		}

		& .MuiFilledInput-underline {
			&::before,
			&::after {
				border: none !important;
			}
		}

		& .MuiOutlinedInput-notchedOutline {
			border: #707070 !important;
		}

		& .MuiFormLabel-root.Mui-focused {
			font-weight: normal;
			font-size: 16px;
		}

		& button {
			background-color: #e3e829;
			font-family: 'Poppins-Bold.ttf';
			color: #707070;
			padding: 15px;
			width: 120px;
			font-size: 18px;
			margin: auto;
		}
	}
}

@media only screen and (max-width: 600px) {
	#login {
		overflow: scroll;
		& .header {
			& img {
				width: 25%;
			}
		}
	}
}
@media only screen and (min-width: 600px) {
	#login {
		overflow: scroll;
		& .header {
			& img {
				width: 15%;
			}
		}
	}
}
@media only screen and (min-width: 768px) {
	#login {
		overflow: hidden;
		& .header {
			& img {
				width: 25%;
			}
		}
	}
}
@media only screen and (min-width: 992px) {
	#login {
		overflow: hidden;
		& .header {
			& img {
				width: 10%;
			}
		}
	}
}
@media only screen and (min-width: 1200px) {
	#login {
	}
}
