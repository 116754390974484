@font-face {
	font-family: 'Poppins-Regular.ttf';
	src: url(../../assets/fonts/Poppins-Regular.ttf);
}

$color-background-header: #0033a0;
$color-background-contenedor-menssages: #fff;
$color-background-input: #3a3a3a;
$color-brackground-button: transparent;
$color-background-messages-sent: #0033a0;
$color-background-messages-received: #e5e5ea;
$color-background-scroll: #0033a0;
$color-background-line-scroll: #1e1e24;

#chat {
	font-family: 'Poppins-Regular.ttf';

	& .App {
		text-align: center;
		height: 420px;
		margin: 0 auto;
		& section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			// min-height: 100vh;
			margin-top: 0;
			border-radius: 0px;
			height: 100%;
			background-color: $color-background-contenedor-menssages;

			& .titulo-chat {
				background-color: $color-background-header;
				border-radius: 0px;
				min-height: 50px;
				color: white;
				// position: fixed;
				width: 100%;
				max-width: 728px;
				// top: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				z-index: 99;
				padding: 10px;
				box-sizing: border-box;
			}

			& main {
				padding: 10px;
				height: 100%;
				margin: 0;
				overflow-y: scroll;
				display: flex;
				flex-direction: column;

				&::-webkit-scrollbar {
					width: 0.25rem;
					height: 0px;
				}

				&::-webkit-scrollbar-track {
					background: $color-background-line-scroll;
				}

				&::-webkit-scrollbar-thumb {
					background: $color-background-scroll;
				}

				& .fecha {
					font-size: 10px;
				}

				& .message {
					display: flex;
					align-items: center;

					& p {
						max-width: 500px;
						margin-bottom: 12px;
						// line-height: 24px;
						padding: 10px;
						border-radius: 15px;
						position: relative;
						color: white;
						text-align: initial;
						font-size: 18px;
						word-break: break-word;
					}

					& img {
						width: 25px;
						min-height: 25px;
						min-width: 25px;
						height: 25px;
						border-radius: 50%;
						margin: 2px 5px;
					}
				}

				& .sent {
					flex-direction: row-reverse;

					& p {
						color: white;
						background: $color-background-messages-sent;
						align-self: flex-end;
						font-size: 13px !important;
					}
				}

				& .received {
					& p {
						background: $color-background-messages-received;
						color: black;
						font-size: 13px !important;
					}
				}
			}

			& form {
				// height: 10vh;
				// position: fixed;
				// bottom: 0;
				background-color: transparent;
				// width: 100%;
				// max-width: 728px;
				// border-radius: 35px;

				display: flex;
				font-size: 1.5rem;
				border-top: 1px solid $color-background-input;

				& input {
					line-height: 1.5;
					width: 100%;
					font-size: 16px;
					background: transparent;
					color: #707070;
					outline: none;
					border: none;
					padding: 0 10px;
					font-family: 'Poppins-Regular.ttf';
					border-top-left-radius: 35px;
					border-bottom-left-radius: 35px;
				}

				& button {
					width: 20%;
					background-color: $color-brackground-button;
					border: none;
					padding: 15px;
					text-align: center;
					text-decoration: none;
					display: inline-block;
					cursor: pointer;
					font-size: 16px;

					&:disabled {
						opacity: 0.5;
						cursor: not-allowed;
					}
				}
			}
		}
	}

	button ul,
	li {
		text-align: left;
		list-style: none;
	}

	& .vetado {
		opacity: 0.6;
		// display:  none;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
