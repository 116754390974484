::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background: #ccc;
	// display: none;  /* Ocultar scroll */
}

::-webkit-scrollbar-thumb {
	background-color: #0033a0;
	border-radius: 4px;
}

#streaming {
	overflow: hidden;
	background-image: url(../../assets/images/bg.png);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	font-size: 18px;
	color: var(--bg-color-inverted);
	padding: 0 0 60px 0;

	& .header {
		background-color: #0033a0;
		padding: 10px 20px;
		margin-bottom: 10%;

		& img {
			width: 10%;
		}
	}

	& .linea {
		width: 100%;
		height: 15px;
		background: linear-gradient(90deg, #0033a0 0%, #e3e829 100%);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		margin-bottom: 8%;
	}

	& .contenedor-streaming {
		padding: 56.25% 0 0 0;
		position: relative;

		& .estilo-streaming {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	& .contentenedor-chat {
		height: 100%;
	}

	& button {
		background-color: #e3e829;
		font-family: 'Poppins-Bold.ttf';
		color: #707070;
		padding: 15px;
		width: 180px;
		font-size: 18px;
		margin: auto;
		margin-top: 1%;
	}
}

@media only screen and (max-width: 600px) {
	#streaming {
		& .header {
			& img {
				width: 25%;
			}
		}
		& .contentenedor-chat {
			height: 400px;
		}
	}
}
@media only screen and (min-width: 600px) {
	#streaming {
		& .header {
			& img {
				width: 15%;
			}
		}
		& .contentenedor-chat {
			height: auto;
		}
	}
}
@media only screen and (min-width: 768px) {
	#streaming {
		& .header {
			& img {
				width: 25%;
			}
		}
	}
}
@media only screen and (min-width: 992px) {
	#streaming {
		& .header {
			& img {
				width: 10%;
			}
		}
		& .contentenedor-chat {
			height: 100%;
		}
	}
}
@media only screen and (min-width: 1200px) {
	#streaming {
	}
}
